import * as React from "react";
import Image from "next/image";
import icon1Img from '../../../public/assets/clapps/images/cpp-logo.svg';
import icon2Img from '../../../public/assets/clapps/images/node-js-logo.svg';
import icon3Img from '../../../public/assets/clapps/images/typescript-logo.svg';
import icon4Img from '../../../public/assets/clapps/images/postgresql-logo.svg';
import icon5Img from '../../../public/assets/clapps/images/next-js-logo.svg';
import icon6Img from '../../../public/assets/clapps/images/java-logo.svg';
import icon7Img from '../../../public/assets/clapps/images/kotlin-logo.svg';
import icon8Img from '../../../public/assets/clapps/images/swift-logo.svg';

function TechItem(props) {
    return(
        <div className={'lan7_li'}>
            <div className={'lan7_li_block'}>
                <Image
                    src={props.icon}
                    alt="IMAGE"
                    quality={100}
                    // sizes="100vw"
                    style={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '7rem'
                    }}
                />
            </div>
            <p className={'lan7_li_p'}>
                {props.text}
            </p>
        </div>
    )
}

export default function LandingSection7(){
    return(
        <section id={'technology'} className={'lan7'}>
            <h2 className={'lan7_h2'}>Технологии разработки</h2>
            <div className="lan7_block">
                <div className={'lan7_ul'}>
                    <TechItem icon={icon1Img} text={'C++'}/>
                    <TechItem icon={icon2Img} text={'Node.js'}/>
                    <TechItem icon={icon3Img} text={'TypeScript'}/>
                    <TechItem icon={icon4Img} text={'PostgreSQL'}/>
                    <TechItem icon={icon5Img} text={'Next.js'}/>
                    <TechItem icon={icon6Img} text={'Java'}/>
                    <TechItem icon={icon7Img} text={'Kotlin'}/>
                    <TechItem icon={icon8Img} text={'Swift'}/>
                </div>
            </div>
        </section>
    )
}
import * as React from "react";
import {useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/swiper.min.css';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper-element.min.css';
import Image from "next/image";
import slide1Bg from '../../../public/assets/clapps/images/portfolio-bg-1-alt.webp';
import slide2Bg from '../../../public/assets/clapps/images/portfolio-bg-2-alt.webp';
import slide3Bg from '../../../public/assets/clapps/images/portfolio-bg-3-alt.webp';
import slide4Bg from '../../../public/assets/clapps/images/portfolio-bg-4-alt.webp';
import slide5Bg from '../../../public/assets/clapps/images/portfolio-bg-5-alt.webp';
import markerImg from "../../../public/assets/clapps/images/list-marker-alt.svg";
import NextLink from "next/link";
import IconFlaticon from "@/components/IconFlaticon";
import clsx from "clsx";

function SwiperSlideItem({props}){
    return(
        <div className={'flex flex-row lg:h-120 xl:h-[33.5rem] justify-between bg-white-2'}>
            <div className={'flex flex-col px-0 sm:px-10 xl:px-12 py-6 sm:py-10 xl:pt-12'}>
                <p className={'px-6 sm:px-0 pt-2 sm:pt-0 text-xl sm:text-2xl xl:text-3xl text-black-1 font-black uppercase'}>
                    {props.title}
                </p>
                <p className={'mt-2 px-6 sm:px-0 text-sm sm:text-base xl:text-lg text-black-4'}>
                    {props.text}
                </p>
                <div className={'hidden sm:flex flex-wrap lg:flex-nowrap lg:flex-row mt-5 xl:mt-7 gap-2.5'}>
                    {props.badges && props.badges.map((text,i) =>
                        <React.Fragment key={i}>
                            <div className={'flex text-nowrap h-8 xl:h-10 px-3 xl:px-5 items-center bg-black-2 text-sm text-white'}>
                                {text}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={'flex flex-col lg:h-48 xl:h-52 mt-8 sm:mt-9 xl:mt-12 sm:space-y-3.5 '}>
                    {props.list && props.list.map((text,i) =>
                        <React.Fragment key={i}>
                            <div className={'flex flex-row min-h-[4.125rem] sm:min-h-fit px-6 sm:px-0 space-x-5 items-center swiper-alternate-list'}>
                                <Image
                                    src={markerImg}
                                    alt="MARKER"
                                    quality={100}
                                    sizes="100vw"
                                    style={{
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                />
                                <p className={'text-sm sm:text-base xl:text-lg text-black-4 font-normal leading-relaxed'}>{text}</p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <NextLink href={props.link ? props.link : '/'} target='_blank' className={'flex flex-row mt-8 px-6 sm:px-0 items-center text-sm sm:text-22px text-red-1 sm:text-black-1 sm:hover:text-red-1 leading-snug'}>
                    перейти на сайт
                    <IconFlaticon className={'hidden sm:flex ml-5 pt-0.5 text-2xl'} name={'sr-angle-circle-right'}/>
                </NextLink>
            </div>
            {!props.screenMobile && (
                <Image
                    src={props.image}
                    alt="IMAGE"
                    quality={100}
                    // sizes="100vw"
                    style={{
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'none'
                    }}
                />
            )}
        </div>
    )
}

function SwiperSlideItemAlt({props}){
    return(
        <div className={'flex flex-row lg:pb-20 justify-center bg-white'}>
            {!props.screenMobile && (
                <Image
                    src={props.image}
                    alt="IMAGE"
                    quality={100}
                    // sizes="100vw"
                    style={{
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'none'
                    }}
                />
            )}
            <div className={'flex flex-col px-0 sm:px-4 py-6 sm:pt-10 sm:pb-0 xl:pt-12 xl:pb-0'}>
                <p className={'px-6 sm:px-0 pt-2 sm:pt-0 text-xl sm:text-xl xl:text-26px text-black-1 font-black uppercase'}>
                    {props.title}
                </p>
                <p className={'mt-1.5 px-6 sm:px-0 text-sm sm:text-base xl:text-lg text-black-4'}>
                    {props.text}
                </p>
                <div className={'hidden sm:flex flex-wrap lg:flex-nowrap lg:flex-row mt-5 xl:mt-8 gap-2.5'}>
                    {props.badges && props.badges.map((text,i) =>
                        <React.Fragment key={i}>
                            <div className={'flex text-nowrap h-8 xl:h-10 px-3 xl:px-5 items-center bg-black-2 text-sm text-white'}>
                                {text}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={'flex flex-col lg:h-48 xl:h-52 mt-8 sm:space-y-3.5 '}>
                    {props.list && props.list.map((text,i) =>
                        <React.Fragment key={i}>
                            <div className={'flex flex-row min-h-[4.125rem] sm:min-h-fit px-6 sm:px-0 space-x-4 items-center swiper-alternate-list'}>
                                <Image
                                    src={markerImg}
                                    alt="MARKER"
                                    quality={100}
                                    sizes="100vw"
                                    style={{
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                />
                                <p className={'text-sm sm:text-base xl:text-lg text-black-1 font-normal leading-relaxed'}>{text}</p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={'flex flex-col h-full justify-end'}>
                    <NextLink href={props.link ? props.link : '/'} target='_blank' className={'flex flex-row px-6 sm:px-0 items-center text-sm sm:text-lg font-bold text-red-1 sm:text-black-1 sm:hover:text-red-1 leading-snug underline underline-offset-4 decoration-2'}>
                        перейти на сайт
                    </NextLink>
                </div>
            </div>
        </div>
    )
}

export default function LandingSection5(props){
    const swiperRef = useRef();
    const itemsArray = [
        {
            screenMobile: props.screenMobile,
            title: 'ECOMMY',
            text: 'Платформа для аренды готовых интернет-магазинов',
            list: [
                'Интеграция с сервисами 1С',
                'Автоматизированный выпуск доменов и SSL-сертификатов',
                'Интеграции методов оплаты и доставки товара',
                'Базовая SEO-оптимизация интернет-магазинов',
                'Панель управления контентом магазина (CMS)'
            ],
            badges: [
                'Design UI/UX',
                'Frontend',
                'Backend',
                'SEO'
            ],
            image: slide1Bg,
            link: 'https://ecommy.ru'
        },
        {
            screenMobile: props.screenMobile,
            title: 'Я умею',
            text: 'Сервис подбора исполнителей для выполнения разных задач',
            list: [
                'Тендерная система поиска исполнителя',
                'Административная панель управления сервисом',
                'Рейтинговые алгоритмы',
                'Интеграция платежных методов',
                'Приложение Android/iOS'
            ],
            badges: [
                'Design UI/UX',
                'Frontend',
                'Backend',
                'Android/iOS',
                'SEO'
            ],
            image: slide2Bg,
            link: 'https://яумею.com'
        },
        {
            screenMobile: props.screenMobile,
            title: 'Я умею.бизнес',
            text: 'Сервис для бизнеса по подбору самозанятых исполнителей',
            list: [
                'Система управления организацией (CMS)',
                'Интеграции с сервисами ФНС РФ',
                'Электронный документооборот',
                'Генерация ЭЦП для дистанционного подписания документов',
                'Безопасная сделка и автоматизация выплат'
            ],
            badges: [
                'Design UI/UX',
                'Frontend',
                'Backend',
                'SEO'
            ],
            image: slide3Bg,
            link: 'https://бизнес.яумею.com'
        },
        {
            screenMobile: props.screenMobile,
            title: 'Ди-тек',
            text: 'Торгово-сервисная компания, автоматизация бизнеса',
            list: [
                'Разработка web-сервисов 1С',
                'Многоуровневая система атрибутов товара',
                'Система создания комплектов товара',
                'Панель управления контентом',
                'Интеграция ChatGPT в панель управления сайтом'
            ],
            badges: [
                'Design UI/UX',
                'Frontend',
                'Backend',
                'SEO'
            ],
            image: slide4Bg,
            link: 'https://d-tech.pro'
        },
        {
            screenMobile: props.screenMobile,
            title: 'ТЕХПЛЕЙС.РФ',
            text: 'Интернет-магазин автозапчастей и аксессуаров',
            list: [
                'Подключение API оптовых поставщиков',
                'Автоматизированный обмен с Web-сервисами 1C',
                'Расчет времени и стоимости доставки через API СДЭК',
                'Эквайринг и система оплаты "Долями" от Т-Банка',
                'Подключение электронных каталогов автозапчастей'
            ],
            badges: [
                'Design UI/UX',
                'Frontend',
                'Backend'
            ],
            image: slide5Bg,
            link: 'https://xn--e1aamgtpbz.xn--p1ai/'
        },
    ]
    return(
        <section id={'portfolio'} className={clsx(props.sectionClass, 'flex flex-col max-w-300 w-full sm:px-6 xl:px-0 ')}>
            {(!props.screenMobile && props.breadcrumbCurrent) && (
                <div className={'flex max-w-300 w-full py-12 text-normal'}>
                    <nav>
                        <ul className={'flex flex-row'}>
                            <li>
                                <NextLink href={'/'} className={'text-black-1 hover:text-red-1'}>Главная</NextLink>
                            </li>
                            {(props.breadcrumbLink && props.breadcrumbRoot) && (
                                <li>
                                    <span className={'px-1.5'}>&gt;</span>
                                    <NextLink href={props.breadcrumbLink} className={'text-black-1 hover:text-red-1'}>{props.breadcrumbRoot}</NextLink>
                                </li>
                            )}
                            <li className={'text-red-1'}>
                                <span className={'px-1.5'}>&gt;</span>
                                {props.breadcrumbCurrent}
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
            <h2 className={'py-8 sm:py-12 text-22px sm:text-46px text-black-1 text-center font-black leading-snug'}>
                Портфолио
            </h2>
            <Swiper
                className='max-w-300 w-full mt-2 lg:mt-0'
                modules={[ Autoplay, Pagination, Navigation  ]}
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView="auto"
                spaceBetween={16}
                pagination={{
                    clickable: true,
                }}
                loop
                autoplay={{
                    delay: 6000,
                }}
            >
                <SwiperSlide key={1}>
                    {props.screenMobile ? (
                        <SwiperSlideItem props={itemsArray[0]} />
                    ) : (
                        <SwiperSlideItemAlt props={itemsArray[0]} />
                    )}
                </SwiperSlide>
                <SwiperSlide key={2}>
                    {props.screenMobile ? (
                        <SwiperSlideItem props={itemsArray[1]} />
                    ) : (
                        <SwiperSlideItemAlt props={itemsArray[1]} />
                    )}
                </SwiperSlide>
                <SwiperSlide key={3}>
                    {props.screenMobile ? (
                        <SwiperSlideItem props={itemsArray[2]} />
                    ) : (
                        <SwiperSlideItemAlt props={itemsArray[2]} />
                    )}
                </SwiperSlide>
                <SwiperSlide key={4}>
                    {props.screenMobile ? (
                        <SwiperSlideItem props={itemsArray[3]} />
                    ) : (
                        <SwiperSlideItemAlt props={itemsArray[3]} />
                    )}
                </SwiperSlide>
                <SwiperSlide key={5}>
                    {props.screenMobile ? (
                        <SwiperSlideItem props={itemsArray[4]} />
                    ) : (
                        <SwiperSlideItemAlt props={itemsArray[4]} />
                    )}
                </SwiperSlide>
            </Swiper>
        </section>
    )
}